<template>
  <div class="Login">
    <div class="tit_img">
      <img src="../../assets/images/login/biao.png" alt="" />
    </div>
    <el-card class="box_card">
      <el-row class="flex-col-center">
        <el-col :span="12">
          <p class="tit">个人用户登录</p>
        </el-col>
        <el-col :span="12">
          <p class="text-right">
            还没有账号?<a @click="link('MRegister')">点击注册</a>
          </p>
        </el-col>
      </el-row>
      <div class="form">
        <el-form
          ref="ruleform"
          :model="form"
          :rules="rules"
          label-width="80px"
          label-position="top"
        >
          <el-form-item label="手机号:" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              placeholder="请输入密码"
              @keyup.enter.native="login('ruleform')"
            ></el-input>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="login('ruleform')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <p>忘记密码?<a @click="link('MReset')">点击重置</a></p>
    </el-card>
  </div>
</template>
<script>
let _this
export default {
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^1[3456789]\d{9}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      form: { mobile: '', password: '' },
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },

        password: {
          required: true,
          message: '请输入密码',
          trigger: 'blur',
        },
      },
      loading: false,
    }
  },
  created() {
    _this = this
    this.init()
  },
  methods: {
    init() {},
    link(name) {
      this.$router.push({ name: name })
    },
    go_url(url) {
      var script = document.createElement('script')
      // script.type = "text/javascript";
      script.src =
        'http://sev.netban.cn/api/user/synwp/' +
        this.$store.state.wps_user.mobile
      document.getElementsByTagName('head')[0].appendChild(script)
      script.onload = function() {}
      console.log(url, script)
    },
    login(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'login', this.form)
            .then(data => {
              _this.loading = true
              if (data.data.code == 200) {
                _this.loading = false
                // _this.$message.success('登录成功')
                _this.Storage.setStorage('token', data.data.token)
                _this.$store.commit('setUser', data.data.data)
                _this.go_url()
                // _this.link('Home')
                _this.$router.push({ path: '/home' })
              } else {
                _this.$message.error(data.data.msg)
                _this.loading = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.Login {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: -2;
  background: url('../../assets/images/login/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20vh;
  .tit_img {
    margin: 20px auto;
    width: 60vw;
    img {
      width: 100%;
    }
  }
  .box_card {
    width: 80vw;
    margin: 0px auto;
    p {
      font-size: 10px;
      a {
        color: #6f8bff;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
    }
  }
  .form {
    padding: 20px 20px 0px;
    /deep/.el-form-item__label {
      line-height: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      font-size: 14px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
    .btn {
      text-align: center;
      .el-button {
        width: 153px;
        height: 40px;
        background: #960f23;
        border-radius: 4px;
        border: none;
      }
    }
    /deep/.el-form-item__error {
      position: relative;
    }
  }
}
</style>
